<header>
  <div class="container-fluid">
      <div class="top-block">
          <div class="logo-box">
              <a routerLink="/">
                <img [src]="env.logoUrl" [alt]="env.title">
              </a>
              <h3 *ngIf="env.instanceReception?.label"  class="mt-5 mb-0">{{env.instanceReception.label | translate}}</h3>
          </div>
          <div class="time-box">
              <div class="date">
                {{ clock | date: 'dd.MM.yyyy' }}
              </div>
              <div class="time">
                {{ clock | date: 'HH:mm' }}
              </div>
          </div>
          <div class="icons-box">
            <div class="circle-box">
               <a routerLink="/help" routerLinkActive="active">?</a>
            </div>
            <button type="button" (click)="$event.stopPropagation();toggleLanguageMenu(!isLanguageMenuShown)" class="lang-btn">
                <img *ngIf="!isLanguageMenuShown" src="/assets/img/globe-white.svg" class="btn-icon" alt="Select language">
                <img *ngIf="isLanguageMenuShown" src="/assets/img/times-white.svg" class="btn-icon" alt="Close language selector">
            </button>
            <ul class="lang-menu" [class.hidden]="!isLanguageMenuShown">
                <li>
                    <button type="button" (click)="useLanguage('de')" [class.active]="currentLang === 'de'" class="lang-btn shadow">DE</button>
                </li>
                <li>
                    <button type="button" (click)="useLanguage('fr')" [class.active]="currentLang === 'fr'" class="lang-btn shadow">FR</button>
                </li>
                <li>
                    <button type="button" (click)="useLanguage('en')" [class.active]="currentLang === 'en'" class="lang-btn shadow">EN</button>
                </li>
                <li>
                    <button type="button" (click)="useLanguage('it')" [class.active]="currentLang === 'it'" class="lang-btn shadow">IT</button>
                </li>
            </ul>
        </div>
      </div>
  </div>
</header>

<div class="main-page" style="background-image: url(/assets/alto/images/bg1.jpg);">
  <router-outlet></router-outlet>
</div>

<input
  #autoFocus
  autofocus
  type="text"
  class="hidden"
  autocomplete="off"
  ngxBarCodePut
  [debounce]="300"
  (onDetected)="onQrRead($event)"
>

<div *ngIf="isCheckingIn" class="overlay">
  <div class="overlay-content">
    <h1 class="message fw-bold d-inline-block me-4">
        {{ 'Checking-in' | translate }}
    </h1>
    <div class="loading d-inline-flex">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
    <h5>
      {{'We are confirming your identity, and we\'ll provide you with the access card, if required.' | translate}}
    </h5>
    <img src="assets/img/bell-concierge.svg" alt="error" />
  </div>
</div>

<div *ngIf="isCheckingOut" class="overlay">
  <div class="overlay-content">
    <h1 class="message fw-bold d-inline-block me-4">
        {{ 'Checking-out' | translate }}
    </h1>
    <div class="loading d-inline-flex">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
    <h5>
      {{'Please wait until we check you out.' | translate}}
    </h5>
    <img src="assets/img/person-to-door.svg" alt="info" />
  </div>
</div>

<div *ngIf="!hasConnection" class="overlay">
  <div class="overlay-content">
    <h1>{{'Oops, we\'re offline' | translate}}</h1>
    <h5>
      {{'Seems like someone cut out Internet cable :(' | translate}}<br>
      {{'Hold on tight, Internet is on the way' | translate}}
    </h5>
    <img src="assets/img/exclamation-circle.svg" alt="error" />
  </div>
</div>

<div *ngIf="hasInvalidCard" class="overlay">
  <div class="overlay-content">
    <h1>{{'Invalid card!' | translate}}</h1>
    <h1 class="my-5">{{invalidCardCounter}}</h1>
    <h5>
      {{'Card you are trying to return is not a valid Alto Pont Rouge access card.' | translate}}<br>
      {{'Please take your card, otherwise it will be locked in, and you will have to call a maintenance to get it back.' | translate}}
    </h5>
    <img src="assets/img/exclamation-circle.svg" alt="error" />
  </div>
</div>

<div *ngIf="isIdle" class="overlay">
  <div class="overlay-content">
    <h1>{{'Need help?' | translate}}</h1>

    <h1 class="my-5">{{idleCounter}}</h1>

    <h5>
      {{'Seems like you have been inactive for some time.' | translate}}<br><br>
      {{'If you are ok, just need more time, please click on the button bellow.' | translate}}
    </h5>

    <button class="btn btn-primary" (click)="closeIdleCounter()">{{'I\'m fine, close this' | translate}}</button>

  </div>
</div>
