
export const environment = {
  production: false,
  apiUrl: 'https://api.dev.digitalreception.ch/v1',
  defaultAppLanguage: 'fr',
  hasRemoteLogging: true,
  title: 'Alto Pont Rouge',
  instanceKey: 'alto',
  templateKey: '.alto',
  instanceReception: {
    key: '',
    label: ''
  },
  cardDispenserExtensionId: 'mfnldfnehiikkehlackgjcjkehnmhiak',
  logoUrl: '/assets/alto/icons/logo.svg',
  carouselImages: []
};
